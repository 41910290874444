import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { FallbackView } from "../components/fallback-view/FallbackView";
const CustomWizardPage = lazy(
  () => import("../components/organisms/Wizard/CustomWizardPage"),
);

export function Routes() {
  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route exact path="/bridge" component={CustomWizardPage} />
        <Route
          path="/bridge/:unwrapTxHash/:unwrapAmountState/:unwrapToken"
          component={CustomWizardPage}
        />
        <Redirect exact from="/" to="/bridge" />
        <Redirect to="error/404" />
      </Switch>
    </Suspense>
  );
}
