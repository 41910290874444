import { InjectedConnector } from "wagmi/connectors/injected";

declare global {
  interface Window {
    evmproviders: any;
  }
}
export class FlintWalletConnector extends InjectedConnector {
  readonly id = "flint";
  constructor(config) {
    super({
      ...config,
      options: {
        name: "Flint",
        getProvider: () =>
          typeof window !== "undefined"
            ? window?.evmproviders?.flint
            : undefined,
      },
    });
  }
}
