import React from "react";
import { ReactElement } from "react";
import {
  Chain,
  useAccount,
  useBalance,
  useConnect,
  useDisconnect,
  useNetwork,
  useProvider,
  useSigner,
} from "wagmi";
import type { Provider, Signer } from "@wagmi/core";
import { formatEther } from "ethers/lib/utils";

export type Web3ContextType = {
  connectMetamask: () => void;
  connectWalletConnect: () => void;
  connectFlintWallet: () => void;
  disconnect: () => void;
  isConnected: boolean;
  isConnecting: boolean;
  provider: Provider;
  signer?: Signer;
  chain?: Chain;
  account?: `0x${string}`;
  mainAssetBalance: string;
};

const Web3Context = React.createContext(null as Web3ContextType | null);

export const Web3Provider: React.FC<{ children: ReactElement }> = ({
  children,
}) => {
  const { connect: connectWallet, connectors } = useConnect();

  const { chain } = useNetwork();
  const { disconnect } = useDisconnect();
  const {
    isConnected,
    isConnecting,
    address: account,
    connector,
  } = useAccount();
  const provider = useProvider();
  const { data: signer } = useSigner();
  const { data: mainAssetBalance } = useBalance({
    address: account,
  });
  const connectMetamask = () => {
    const metamaskConnector = connectors.find(
      (connector) => connector.id === "metaMask",
    );
    connectWallet({
      connector: metamaskConnector,
    });
  };
  const connectWalletConnect = () => {
    const walletConnectConnector = connectors.find(
      (connector) => connector.id === "walletConnect",
    );
    connectWallet({
      connector: walletConnectConnector,
    });
  };
  const connectFlintWallet = () => {
    const flintConnector = connectors.find(
      (connector) => connector.id === "flint",
    );
    connectWallet({ connector: flintConnector });
  };

  return (
    <Web3Context.Provider
      value={{
        connectMetamask,
        connectWalletConnect,
        connectFlintWallet,
        isConnected,
        isConnecting,
        account,
        chain,
        mainAssetBalance: formatEther(mainAssetBalance?.value ?? 0),
        disconnect,
        provider,
        // @ts-ignore
        signer,
      }}
    >
      {children}
    </Web3Context.Provider>
  );
};

export const useWeb3Provider = () => {
  const context = React.useContext(Web3Context);
  if (context === undefined) {
    throw new Error("useWeb3Provider must be used within a Web3Provider");
  }
  return context as Web3ContextType;
};
