/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./Footer.scss";

export function Footer() {
  return (
    <footer className="Footer">
      <a
        href="https://milkomeda.com/terms-of-use"
        target="_blank"
        rel="noreferrer noopener"
      >
        Terms of Use
      </a>
    </footer>
  );
}
