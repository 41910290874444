import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Routes } from "./routing/Routes";
import { ErrorsPage } from "./modules/errors/ErrorsPage";
import { MasterLayout } from "./components/MasterLayout";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { client } from "./web3/wagmi";
import { WagmiConfig } from "wagmi";
import { Web3Provider } from "./web3/Web3Provider";

type Props = {
  basename: string;
};

const App: React.FC<Props> = ({ basename }) => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <WagmiConfig client={client}>
        <Web3Provider>
          <BrowserRouter basename={basename}>
            <Switch>
              <Route path="/error" component={ErrorsPage} />
              <MasterLayout>
                <Routes />
              </MasterLayout>
            </Switch>
          </BrowserRouter>
        </Web3Provider>
      </WagmiConfig>
    </QueryClientProvider>
  );
};

export { App };
