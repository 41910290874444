import React from "react";

import "../scss/app.scss";
import { Footer } from "./Footer";

const MasterLayout: React.FC = ({ children }) => {
  return (
    <>
      <div className="d-flex flex-column flex-root PrincipalContent">
        <div className="d-flex flex-column flex-column-fluid">
          <div className="Header">
            <img src="/media/logos/LogoMilkomeda.svg" alt="Logo Milkomeda" />
          </div>
          <div
            className="content fs-6 d-flex flex-column-fluid"
            id="kt_content"
          >
            <div className="container">
              <div className="Dashboard">{children}</div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export { MasterLayout };
