import { ethers } from "ethers";

export const MAIN_ASSET_UNIT = "mADA";

export const GeroWalletContractId =
  "0xeA55e366Eb0f4cFAAf8d83dC1fC7286F11b5b1cb";
export const Charli3ContractId = "0xdE482b84360c248C0d7260826a8B06a5A837235d";
export const USDCoinNomadContractId =
  "0x5a955FDdF055F2dE3281d99718f5f1531744B102";
export const TetherUSDNomadContractId =
  "0xab58DA63DFDd6B97EAaB3C94165Ef6f43d951fb2";
export const WBTCNomadContractId = "0x48AEB7584BA26D3791f06fBA360dB435B3d7A174";
export const WETHNomadContractId = "0x5950F9B6EF36f3127Ea66799e64D0ea1f5fdb9D1";
export const IAGONContractId = "0x658b95858457549cFC0af513b1b902aB0F6E6946";
export const CardStarterContractId =
  "0xa350679eaFDb5836da9A796fF721a1fd8415c75F";

export const C1MainnetChainInfo = {
  chainId: ethers.utils.hexValue(2001),
  rpcUrls: ["https://rpc-mainnet-cardano-evm.c1.milkomeda.com/"],
  chainName: "Milkomeda Cardano (C1)",
  blockExplorerUrls: ["https://explorer-mainnet-cardano-evm.c1.milkomeda.com"],
  nativeCurrency: {
    name: "mADA",
    symbol: "mADA",
    decimals: 18,
  },
};

export const C1DevnetChainInfo = {
  chainId: ethers.utils.hexValue(200101),
  rpcUrls: ["https://rpc-devnet-cardano-evm.c1.milkomeda.com/"],
  chainName: "Milkomeda Cardano (C1)",
  blockExplorerUrls: ["https://explorer-devnet-cardano-evm.c1.milkomeda.com"],
  nativeCurrency: {
    name: "mTADA",
    symbol: "mTADA",
    decimals: 18,
  },
};
