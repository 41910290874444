import ReactDOM from "react-dom";
// Apps
import { App } from "./app/App";
import "./_start/assets/sass/style.scss";
import BigNumber from "bignumber.js";

BigNumber.config({
  EXPONENTIAL_AT: 1e9, // max at 10^(1e9)
  DECIMAL_PLACES: 80, // slightly higher than log_10(2^256)
});

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Start mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

// /* const mock = */ _redux.mockAxios(axios);
/**
 * Inject Start interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
// _redux.setupAxios(axios, store);

ReactDOM.render(<App basename={PUBLIC_URL} />, document.getElementById("root"));
