import { Chain, configureChains, createClient } from "wagmi";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { C1DevnetChainInfo, C1MainnetChainInfo } from "../../constants";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { FlintWalletConnector } from "./flintConnector";
// import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { WalletConnectLegacyConnector } from "wagmi/connectors/walletConnectLegacy";

const isMainnet = process.env.REACT_APP_CARDANO_NETWORK === "mainnet";

const NETWORK_NAME = isMainnet ? "Milkomeda C1 Mainnet" : "Milkomeda C1 Devnet";

const CHAIN_ID = isMainnet
  ? C1MainnetChainInfo.chainId
  : C1DevnetChainInfo.chainId;

const RPC_URL = isMainnet
  ? C1MainnetChainInfo.rpcUrls[0]
  : C1DevnetChainInfo.rpcUrls[0];

export const supportedChain: Chain = {
  id: Number(CHAIN_ID),
  name: NETWORK_NAME,
  network: NETWORK_NAME,
  nativeCurrency: {
    name: isMainnet
      ? C1MainnetChainInfo.nativeCurrency.name
      : C1DevnetChainInfo.nativeCurrency.name,
    symbol: isMainnet
      ? C1MainnetChainInfo.nativeCurrency.symbol
      : C1DevnetChainInfo.nativeCurrency.symbol,
    decimals: 18,
  },
  rpcUrls: {
    public: { http: [RPC_URL] },
    default: { http: [RPC_URL] },
  },
  blockExplorers: {
    etherscan: { name: "", url: "" },
    default: {
      name: "",
      url: isMainnet
        ? C1MainnetChainInfo.blockExplorerUrls[0]
        : C1DevnetChainInfo.blockExplorerUrls[0],
    },
  },
};

const { provider, webSocketProvider } = configureChains(
  [supportedChain],
  [
    jsonRpcProvider({
      rpc: () => ({
        http: RPC_URL,
      }),
    }),
  ],
);

export const client = createClient({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({
      chains: [supportedChain],
    }),
    new WalletConnectLegacyConnector({
      chains: [supportedChain],
      options: {
        rpc: {
          2001: "https://rpc-mainnet-cardano-evm.c1.milkomeda.com/",
          200101: "https://rpc-devnet-cardano-evm.c1.milkomeda.com/",
        },
      },
    }),
    // TODO: update to v2 when upgrading to wagmi v1
    // new WalletConnectConnector({
    //   chains: [supportedChain],
    //   options: {
    //     projectId: "33c53d690a2c39f179a79a3d98ee9c8e",
    //   },
    // }),
    new FlintWalletConnector({
      chains: [supportedChain],
    }),
  ],
  provider,
  webSocketProvider,
});
